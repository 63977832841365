@keyframes fadein {
    0%   {
        opacity:0;
    }
    //50%  {
    //    opacity:0.5;
    //    filter: grayscale(1);
    //}
    100% {
        opacity:1;
    }
}
@keyframes scaleY {
    0%   {
        transform: scaleY(0);
    }
    //50%  {
    //    opacity:0.5;
    //    filter: grayscale(1);
    //}
    100% {
        transform: scaleY(1);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sp-spin {
    animation: spin 0.8s linear infinite;   
}

@keyframes sp-blink {
    0%   {
        opacity:1;
    }
    50%  {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.sp-blink {
    animation: sp-blink 2s linear infinite;
}

// typing
@keyframes typing { to { width: 0 } }
@keyframes caret { 50% { color: transparent } }
