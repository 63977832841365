@page {
    margin: 1cm;
}

.print-only {
    display: none;
}

@media print {
    .page-break-after {
        display: block;
        page-break-after: always;
        position: relative;
    }
    
    .no-print {

    }
}