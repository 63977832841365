.sp-breadcrumbs {
    display: flex;
    i {
        margin: 0 .5em;
    }
    a {
        color: #fff;
        font-weight: 400;
        
        &:hover {
            text-decoration: underline;
        }
    }    
}


@media screen and (max-width: 600px) {
    .sp-breadcrumbs {
        a, i {
            display: none;
        }
        
    }
    
}