* { font-family: 'Raleway', serif; }
.ngx-toastr { font-family: 'Raleway', serif !important; font-weight: 600; }

body {
    font-size: 16px;
}
button {
    font-size: 1em;
}

em {
    font-style: italic;
}

a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    
    &.sp-link {
        &.blue {
            color: #037EFF !important;
        }
        
        &.disabled {
            color: #ccc !important;
            cursor: not-allowed;
        }
        
        &:hover {
            text-decoration: underline;
        }
    }
    
    &.sp-button {
        display: flex;
        align-items: center;
        justify-content: center;
        
        font: normal 600 19px/19px Raleway;
        padding: 15px 33px;
        border: 1px solid #037EFF;
        color: #037EFF;

        background-color: #fff;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 10px;

        transition: background-color .2s, color .2s;

        &:hover {
            text-decoration: none;
            color: #fff;
            background-color: #037EFF;
        }
        
        &.blue,
        &.yellow {
            border: none;
            background-color: #FF851B;
            border-radius: 8px;
            padding: 8px 14px;
            color: #fff;
            font: normal 800 13px/15px Raleway;
            
            &:hover {
                background-color: #E67617;
            }
        }
        &.blue {
            background-color: #009FDD;
        }
        
        &.primary {
            color: #fff;
            background-color: #037EFF;

            &:hover {
                color: #037EFF;
                background-color: #fff;
            }
        }

        &.secondary {
            border-color: #d1d1d1;
        }
        
        &.disabled {
            cursor: not-allowed;
            color: #B5B5B5;
            background-color: #CED3D9;
            border: 0;
        }
    }
}

h1, h2, .h2, h3 {
    color: #fff;
    font-weight: 800;
    font-family: 'Raleway', serif;
    font-size: 1.5em;
}
h2, .h2 {
    margin-bottom: 1em;
    font-size: 2em;
}
h3 {
    margin-bottom: 1em;
    font-size: 1.5em;
}

strong {
    font-weight: 800;
}

.flex {
    display: flex;
}
.flex-column {
    display: flex;
    flex-flow: column;
    
    &.overflow {
        min-width: 0;
    }
}
.flex-row {
    display: flex;
    flex-flow: column;
}
.warn {
    color: brown;
}
.nobr {
    white-space:nowrap;
}
.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.center {
    text-align: center;
}

.italic {
    font-style: italic;
}
.semi-bold {
    font-weight: 600;
}
.bold {
    font-weight: 700;
}
.extra-bold {
    font-weight: 800;
}
.small {
    font-size: 15px;
    line-height: 18px;
}
.underline {
    text-decoration: underline;
}
.number {
    font-family: "DM Sans", sans-serif !important;
}
.sp-typography {

    h1 {
        margin: 24px 0 27px 0;
        color: #00376F;
        font-size: 30px;
        
        h2, .h2 {
            color: #00376F;
            margin-bottom: 0;
        }
    }
    h2, .h2 {
        display: block;
        color: #FF851B;
        font-size: 24px;
        line-height: 29px;
        font-weight: 500;


    }
    h3 {
        color: #FF851B;
        font-size: 16px;
        font-weight: 500;
    }
    p {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;

        b {
            font-weight: 600;
        }
        a {
            color: #037EFF;
        }
    }

    blockquote, dt, dd, .blockquote {
        margin: 45px 20px 45px 20px;
        font-size: 16px;
        line-height: 25px;
        color: #00376F;
        font-weight: 800;
        padding-left: 22px;
        border-left: 2px solid #FF851B;
        
        &.alternative {
            font-weight: 500;
            color: #000;
            
            b {
                color: #00376F;
                font-weight: 800;
            }
        }
    }
    
    dt {
        margin-bottom: 0;
    }
    dd {
        margin-top: 0;
        font-weight: 400;
        color: #000;
    }
    
    a {
        color: #037EFF;
    }
    
    ol {
        font-weight: 500;
        line-height: 1.5;
        padding-left: 40px;
        margin-bottom: 40px;
        list-style-type: decimal;

        &.a {
            list-style-type: lower-alpha;
            margin-bottom: 5px;
        }
    }
    
    ul {
        padding-left: 40px;
        margin-bottom: 40px;
        list-style-type: disc;
        line-height: 26px;
    }
    
    button {
        cursor: pointer;
        border-radius: 10px;
        color: #fff;
        border: none;
        
        outline: 2px solid transparent;
        transition: outline-color .2s;

        span {
            font-size: 19px;
            font-weight: 500;
        }
        
        &.primary {
            padding: 20px;
            flex-flow: column;

            font-weight: 700;
            font-size: 26px;
            line-height: 28px;

            
            background-color: #FF851B;        
            margin: 52px auto 0 auto;
            
            &:hover {
                outline-color: black;
            }
        }
        
        &.alternative {
            background: #037EFF;
            font-size: 19px;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 1299px) {
    
    .sp-typography {
        h3 {
            margin: 0;
        }
        h1 {
            font-size: 27px;
            line-height: 31px;
        }
        h2 {
            margin: 20px 0 30px 0;
            font-size: 19px;
            line-height: 22px;
        }
        .sub-title {
            font-size: 21px;
            line-height: 26px;
        }
    }
}