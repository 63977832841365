form {
    display: flex;
    flex-flow: column;
}
.sp-form {
    display: flex;
    flex-flow: column;
    //align-items: flex-start;

    label {
        //font-size: 1.1875em;
        font-weight: 500;
        margin-bottom: .375em;
        
        position: relative;

        app-stt-input-toggle {
            padding: 0 5px;
            position: absolute;
            right: -10px;
            bottom: 0;
        }
        
        &:hover {
            app-stt-input-toggle {
                display: block;
            }
            
        }
    }
    
    button, input, textarea {
        margin-bottom: 24px;
        
        font-size: 19px;
        line-height: 22px;
        font-weight: 600;

        color: #00376F;
        background-color: #fff;
        //padding: 0.9375em 1.25em 1em 1em;
        //border: 3px solid #79CBFF;
        border: none;
        //height: 50px;
        padding: 13px 13px 14px 12px;
        //padding: .875em 1em;
        border-radius: 10px;
        
        transition: color, background-color, border-color .2s;
        
        &:hover {
            //box-shadow: 1px 1px 6px #555;
        }
        
        
        //.ng-submitted &.ng-invalid,
        //&.ng-invalid.ng-touched {
        //    background-color: #E76161;
        //    color: #fff;
        //
        //    &::placeholder {
        //        color: #fff;
        //    }
        //}
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        
        height: 57px;
        
        width: 100%;
        cursor: pointer;
        text-align: center;
        
        i {
            margin-right: .2em;
        }
        
        &.delete {
            color: #FE0201;
        }
    }
    input, textarea {
        width: 100%;
        //background-color: #D1D1D1;
        //mix-blend-mode: multiply;
        //background-color: #3C8FC4;
        //color: #fff;

        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjKCsrawAABKsB4yby5ucAAAAASUVORK5CYII=);
        color: #fff;
        background-blend-mode: multiply;
        background-color: #379fe0;
        outline: none;


        &::placeholder {
            color: #B5B5B5;
        }
        
        //&:placeholder-shown,
        //&:-internal-autofill-selected,
        //&:-webkit-autofill,
        //&:-webkit-autofill:focus {
            //background-color: #3C8FC4 !important;
            //color: #fff !important;
        //}
        
        //&:focus {
        //    outline: none;
        //    border-color: transparent;
        //    color: #fff;
        //    background-color: #3C8FC4;
        //}
        
        &[type=checkbox] {
            background-blend-mode: color;
        }

        &:focus {
            outline: 3px solid #79CBFF;
            background-color: #fff;
            background-image: none;
            color: black;
        }
        
        &:hover, &:focus {
            outline: 3px solid #79CBFF;
        }

        &.speech-to-text-input {
            outline-color: brown;
        }
    }
    
    input[type=radio] {
        &:hover {
            outline: 3px solid transparent;
        }
    }
    
    button {
        &:focus {
            background-color: #fff;
        }
    }
    

    .ng-submitted.ng-invalid {
        input.ng-invalid {
            background-color: #E76161;
            color: #fff;

            &::placeholder {
                color: #fff;
            }
        }
    }
    
    input[type=submit],
    button {
        border: 0;
        //opacity: .9;
        //transition: opacity .2s;
        
        &.primary {
            background-color: #FF851B;
            color: #fff;
            
            &:disabled {
                cursor: not-allowed;
                background-color: #ccc;
            }
        }

        &:not(:disabled):not(.alternative):hover {
            outline: 2px solid #000;
            //box-shadow: 1px 1px 6px #555;
        }
        
        &.alternative {
            align-self: flex-start;
            width: auto;
            
            display: flex;
            align-items: center;
            font-size: 1em;
            font-weight: 700;
            padding: 0;

            color: #fff;
            background: transparent;
            transition: color .2s;
            
            .material-icons {
                transition: color .2s;
                
                margin-right: 16px;
                padding: 5px;
                font-size: 40px;
                font-weight: bold;
                background-color: #fff;
                border-radius: 10px;
                color: #037EFF;
                
                box-shadow: 0 3px 6px #00000029;
            }
            
            &:hover {
                color: #FF851B;
                .material-icons {
                    color: #FF851B;
                }
            }
        }
    }
}

.sp-form-alternative {
    input, textarea {
        border: none;
        border-radius: 10px;
        background-color: #E4E9EF;
        background-blend-mode: color;
        color: #000;
    }
    button.alternative {
        color: #000;
    }
}

hr {
    border: 0;
    margin: 0;
    border-bottom: 1px solid #D1D1D1;
}

.sp-checkbox {
    display: flex;
    
    label {
        margin-left: 1em;
        cursor: pointer;
    }
    
    input[type="checkbox"] {
        flex-shrink: 0;
        flex-grow: 0;
        cursor: pointer;
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* Not removed via appearance */
        margin: 0;

        font: inherit;
        color: currentColor;
        width: 22px;
        height: 22px;
        background-color: #fff;
        border: 1px solid #B9B9B9;
        //opacity: .7;
        //transition: opacity .2s;
        //border: 0.15em solid currentColor;
        border-radius: 0.15em;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;
    }

    input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        ///* Windows High Contrast Mode */
        //background-color: CanvasText;
        background-color: #fff;
    }
    input[type=checkbox].indeterminate {
        &::before {
            clip-path: inset(41% 0 43% 0);
            transform: scale(1);
        }
    }
    
    input[type="checkbox"]:checked {
        background-color: #00376F;
        &::before {
            transform: scale(1);
        }
    }
    
    input[type="checkbox"]:checked,  input[type="checkbox"]:hover {
        //opacity: 1;
    }

    //input[type="checkbox"]:focus {
    //    outline: max(2px, 0.15em) solid currentColor;
    //    outline-offset: max(2px, 0.15em);
    //}

    input[type="checkbox"]:disabled {
        background: #EFEFEF;
        
        //--form-control-color: var(--form-control-disabled);
        //color: var(--form-control-disabled);
        cursor: not-allowed;

        &::before {
            transform: scale(0);
        }
    }
}

button.sp-flat {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: .8125em;
    font-weight: 500;
    height: 2.5em;
    border: 0;
    border-radius: 1.3em;
    background-color: #F1F4F7;
    color: #037EFF;
    padding: .4em 1.2em;
}

button {

    &:disabled {
        background-color: #ccc !important;
    }
    
    span.sp-spin {
        margin-right: .5em;
    }
}


@media screen and (min-width: 600px) {
    .sp-form {
        width: 460px;
        margin: 0 auto;
    }
}



