@media screen and (min-width: 1300px) {

    app-call-report-builder {
        .actions {
            background: #fff;
            position: sticky;
            bottom: 0;
            border-top: 2px solid #F8F9FB;
            padding: 24px;
            margin: 0 -24px;
        }
    }

}