@media screen and (max-width: 1299px) {
    #hubspot-messages-iframe-container{
        margin-right: -500px;
    }
}

body.hubstop-chat-disabled {
    #hubspot-messages-iframe-container {
        margin-right: -500px;
    }    
}